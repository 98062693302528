<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG REPORTES -->
         <v-dialog v-model="dialogReportes" persistent max-width="450">
            <v-form>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md fluid>
                        <v-layout wrap>
                           <v-flex xs12 mt-4>
                              <span
                                 class="text-h5 font-weight-medium justify-center"
                              >
                                 <span class="d-flex justify-center"
                                    >Reportes de becas aplicadas</span
                                 >
                                 <br />
                                 <span
                                    class="colorPrimary d-flex justify-center"
                                 >
                                    Ciclo {{ selectCicloBecas }}
                                 </span>
                              </span>
                           </v-flex>
                           <v-flex xs12 mt-2 class="d-flex justify-center">
                              <v-radio-group v-model="opt" row>
                                 <v-layout wrap ml-10>
                                    <v-flex xs6>
                                       <v-radio
                                          label="Completo"
                                          value="r-completo"
                                       ></v-radio>
                                    </v-flex>
                                    <v-flex xs6>
                                       <v-radio
                                          label="Facultad"
                                          value="r-facultad"
                                       ></v-radio>
                                    </v-flex>
                                    <v-flex xs6>
                                       <v-radio
                                          label="Carrera"
                                          value="r-carrera"
                                       ></v-radio>
                                    </v-flex>
                                    <v-flex xs6>
                                       <v-radio
                                          label="Beneficiario"
                                          value="r-beneficiario"
                                       ></v-radio
                                    ></v-flex>
                                 </v-layout>
                              </v-radio-group>
                           </v-flex>
                           <v-flex xs12 mt-2>
                              <v-btn
                                 color="green darken-3 white--text"
                                 block
                                 @click="reporteBecasAplicadas"
                              >
                                 <v-icon left>fa-file-excel</v-icon>
                                 Excel
                              </v-btn>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -20px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        text
                        @click.native="dialogReportes = false"
                        >Salir</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <v-dialog v-model="dialogReporteLoading" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Procesando...
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>
         <!-- DIALOG CREAR / EDITAR BECA -->
         <v-dialog v-model="dialogBeca" persistent max-width="600px">
            <v-form ref="formBecas" v-model="validBeca" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="carnet"
                                 v-mask="'####-AA-###'"
                                 :readonly="editedIndex != -1"
                                 :rules="rulesCarnet"
                                 color="primary"
                                 label="Carnet"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="periodoLectivo"
                                 :readonly="editedIndex != -1"
                                 :rules="rulesPeriodoLectivo"
                                 color="primary"
                                 label="Periodo Lectivo"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12>
                              <v-select
                                 v-model="selectCategoria"
                                 :items="cmbCategorias"
                                 :rules="rulesTipoBeca"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="nombre"
                                 label="Categoria"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              ></v-select>
                           </v-flex>
                           <v-flex xs12>
                              <v-autocomplete
                                 v-model="selectTipo"
                                 :items="cmbTipoBeca"
                                 :rules="rulesTipoBeca"
                                 color="primary"
                                 item-text="descripcion"
                                 item-value="tipoBecaId"
                                 label="Tipo de Beca"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              ></v-autocomplete>
                           </v-flex>
                           <v-flex xs12>
                              <span class="text-subtitle-1 font-weight-medium">
                                 Información ingresada por el alumno
                              </span>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="nombres"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Nombres"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="apellidos"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Apellidos"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="dui"
                                 v-mask="'########-#'"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Dui"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="nit"
                                 v-mask="'####-######-###-#'"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Nit"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="email"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Email"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="direccion"
                                 disabled
                                 readonly
                                 color="primary"
                                 label="Direccion"
                              ></v-text-field>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validBeca"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR BODEGA -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover beca</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover la beca?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ carnet }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ periodoLectivo }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerBecas"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <!-- DIALOG SUBIR COMPROBANTE DE BECA -->
         <ComprobanteBecario
            v-model="dialogBecaComp"
            :carnet="carnet"
            :periodos-lectivos="periodosLectivos"
         />

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Becas aplicadas</v-toolbar-title
               >
               <div class="mt-6 ml-3" style="width: 150px">
                  <v-autocomplete
                     v-model="selectCicloBecas"
                     label="Ciclo"
                     :items="cmbCiclosBecas"
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        v-on="on"
                        @click="nuevaBeca()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px"> Nueva Beca </span>
               </v-tooltip>

               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn text dark v-on="on" @click="dialogReportes = true">
                        Reportes <v-icon right>fa-print</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px"> Reportes de Becas </span>
               </v-tooltip>

               <v-btn
                  text
                  color="white darken-3"
                  @click="toggleResumen = !toggleResumen"
               >
                  Datos de Becas
               </v-btn>
            </v-toolbar>
            <v-layout wrap>
               <!-- RESUMEN PAGOS DEL DIA -->
               <v-flex xs12 class="mb-6">
                  <v-item-group>
                     <v-sheet class="overflow-y-auto" max-height="600" tile>
                        <v-item>
                           <v-sheet
                              tile
                              style="cursor: pointer"
                              @click="toggleResumen = !toggleResumen"
                           >
                              <v-expand-transition>
                                 <v-responsive
                                    v-if="toggleResumen"
                                    max-width="100%"
                                 >
                                    <v-card class="borderDetallesPrimary">
                                       <v-layout
                                          wrap
                                          text-center
                                          align-end
                                          class="my-3"
                                       >
                                          <v-flex
                                             v-for="(
                                                dato, index
                                             ) in estadisticaBeca"
                                             :key="index"
                                             xs12
                                             sm3
                                          >
                                             <div
                                                class="px-4 grey-darken3--text font-weight-medium text-subtitle-1"
                                             >
                                                {{ dato.categoria }}
                                             </div>
                                             <div
                                                class="font-weight-medium colorPrimary"
                                                style="font-size: 22px"
                                             >
                                                {{ dato.cantidad }} aprobadas
                                             </div>
                                             <div
                                                class="font-weight-medium"
                                                style="color: #616161"
                                             >
                                                {{ dato.aprobadasPorConsejo }}
                                                aprob. por consejo
                                             </div>
                                          </v-flex>
                                       </v-layout>
                                    </v-card>
                                 </v-responsive>
                              </v-expand-transition>
                           </v-sheet>
                        </v-item>
                     </v-sheet>
                  </v-item-group>
               </v-flex>
               <!-- DATATABLE becas -->
               <v-flex xs12>
                  <v-data-table
                     :headers="headers"
                     :items="becas"
                     :loading="loadingBecas"
                     loading-text="Listando becas, por favor espere..."
                     no-data-text="No se encontraron becas para el ciclo seleccionado."
                     :search="search"
                     :footer-props="{
                        itemsPerPageOptions: [10, 25, 50],
                        itemsPerPageText: 'Filas por página:'
                     }"
                     multi-sort
                     no-results-text="Búsqueda sin resultados"
                     class="elevation-1"
                  >
                     <template #item.opciones="{ item }">
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 small
                                 fab
                                 color="amber darken-3"
                                 v-on="on"
                                 @click="editItem(item)"
                              >
                                 <v-icon>fa-pen</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px"> Editar </span>
                        </v-tooltip>

                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 small
                                 fab
                                 color="primary"
                                 v-on="on"
                                 @click="deleteItem(item)"
                              >
                                 <v-icon>fa-trash-alt</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px"> Eliminar </span>
                        </v-tooltip>
                        <v-tooltip bottom max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 small
                                 fab
                                 color="primary"
                                 v-on="on"
                                 @click="showDetalles(item)"
                              >
                                 <v-icon>fas fa-bars</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px">
                              Comprobantes de Beca
                           </span>
                        </v-tooltip>
                     </template>
                     <template #item.montoDonado="{ item }">
                        $ {{ numFormatIntl(item.montoDonado) }}
                     </template>
                     <template #item.reaplicacion="{ item }">
                        <v-tooltip
                           v-if="item.reaplicacion"
                           bottom
                           max-width="300"
                           color="primary"
                        >
                           <template #activator="{ on }">
                              <v-icon color="primary" v-on="on"
                                 >fas fa-check</v-icon
                              >
                           </template>
                           <span style="font-size: 15px"> Si </span>
                        </v-tooltip>
                        <v-tooltip
                           v-else
                           bottom
                           max-width="300"
                           color="primary"
                        >
                           <template #activator="{ on }">
                              <v-icon color="primary" v-on="on"
                                 >fas fa-times</v-icon
                              >
                           </template>
                           <span style="font-size: 15px"> No </span>
                        </v-tooltip>
                     </template>

                     <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                     <template slot="no-data">
                        <div v-if="becas.length == 0">
                           <v-container>
                              <v-layout wrap align-center>
                                 <v-flex xs12 class="text-center">
                                    <v-btn color="primary" @click="reset"
                                       >Resetear</v-btn
                                    >
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </div>
                     </template>
                  </v-data-table>
               </v-flex>
            </v-layout>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'
import ComprobanteBecario from '../components/ComprobanteBecario'
import XLSX from 'xlsx'
import formatters from '../mixins/formattersLib'

export default {
   name: 'Becas',
   components: {
      ComprobanteBecario: ComprobanteBecario
   },
   directives: {
      mask
   },
   mixins: [formatters],
   data: () => ({
      // Variables
      estadisticaBeca: [],
      dialogBeca: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Ciclo', value: 'periodoLectivo', class: 'titulo' },
         { text: 'Tipo', value: 'tipoBeca', class: 'titulo' },
         { text: 'Categoria', value: 'categoria', class: 'titulo' },
         { text: 'Carnet', value: 'carnet', class: 'titulo' },
         {
            text: 'Nombre',
            value: 'nombreCompleto',
            class: 'titulo',
            width: 180
         },
         {
            text: 'Benficiario',
            value: 'tipoBeneficiario',
            class: 'titulo',
            width: 150
         },
         {
            text: 'Cuotas',
            value: 'cuotas',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Monto a becar',
            value: 'montoDonado',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Comp. enviados',
            value: 'comprobantesIngresados',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Reaplicación',
            value: 'reaplicacion',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            width: 175,
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      toggleResumen: false,
      search: '',
      pagination: {},
      result: [],
      becas: [],
      dialogReportes: false,
      swGeneraVenta: false,
      dialogBecaComp: false,
      cmbCiclosBecas: [],
      selectCicloBecas: '',
      loadingBecas: false,
      opt: 'r-completo',
      dialogReporteLoading: false,

      validBeca: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      selectCategoria: 'Administrativo',
      cmbCategorias: [],

      // Propiedades bodega
      becaId: '',
      carnet: '',
      periodoLectivo: '',
      periodosLectivos: [],
      selectTipo: '',
      cmbTipoBeca: [],
      nombres: '',
      apellidos: '',
      email: '',
      direccion: '',
      dui: '',
      nit: '',

      // Reglas de validacion de formularios
      rulesCarnet: [(v) => !!v || 'Ingrese una carnet'],
      rulesPeriodoLectivo: [(v) => !!v || 'Ingrese una periodoLectivo'],
      rulesTipoBeca: [(v) => !!v || 'Ingrese una periodoLectivo']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1 ? 'Nueva beca' : 'Editar beca'
      }
   },
   watch: {
      selectCicloBecas() {
         this.listarBecas()
         this.obtenerEstadisticas()
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(4, 'ver')) {
         this.listarComboBoxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarBecas() {
         this.becas = []
         this.loadingBecas = true
         // Obteniendo becas
         axios
            .get('api/Beca?PeriodoLectivo=' + this.selectCicloBecas)
            .then((response) => {
               this.becas = response.data
               this.loadingBecas = false
            })
            .catch((error) => {
               console.log(error)
               this.loadingBecas = false
            })
      },
      // Si no cargaron bien las becas en el datatable se puede resetear
      reset() {
         this.listarBecas()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/Beca/' + item.becaId)
            .then((response) => {
               this.result = response.data
               this.becaId = this.result.becaId
               this.carnet = this.result.carnet
               this.periodoLectivo = this.result.periodoLectivo
               this.selectTipo = this.result.tipoBecaId
               this.selectCategoria = this.result.categoria
               this.nombres = this.result.nombres
               this.apellidos = this.result.apellidos
               this.email = this.result.email
               this.dui = this.result.dui
               this.nit = this.result.nit
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogBeca = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.carnet = item.carnet
         this.periodoLectivo = item.periodoLectivo
         this.becaId = item.becaId

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogBeca = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formBecas.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/Beca/' + this.becaId, {
                     periodoLectivo: this.periodoLectivo,
                     carnet: this.carnet,
                     tipoBecaId: this.selectTipo,
                     categoria: this.selectCategoria
                  })
                  .then(() => {
                     this.listarBecas()
                     this.close()
                     this.snackText = 'Beca editada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            } else {
               // Codigo para guardar

               axios
                  .post('api/Beca', {
                     periodoLectivo: this.periodoLectivo,
                     carnet: this.carnet,
                     tipoBecaId: this.selectTipo,
                     categoria: this.selectCategoria
                  })
                  .then(() => {
                     this.listarBecas()
                     this.close()
                     this.snackText = 'Beca agregada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formBecas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.carnet = ''
         this.periodoLectivo = ''
         this.nombres = ''
         this.apellidos = ''
         this.email = ''
         this.dui = ''
         this.nit = ''
         this.selectTipo = ''
      },
      nuevaBeca() {
         this.dialogBeca = true
      },
      removerBecas() {
         // Enviando metodo delete
         axios
            .delete('api/Beca/' + this.becaId)
            .then(() => {
               this.listarBecas()
               this.close()
               this.snackText = 'Beca removida exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboBoxes() {
         // Obteniendo sub categorias
         axios
            .get('api/TipoBeca')
            .then((response) => {
               this.cmbTipoBeca = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/Beca/periodosLectivos')
            .then((response) => {
               this.cmbCiclosBecas = response.data
               this.selectCicloBecas = response.data[response.data.length - 1]
            })
            .catch(() => {
               //console.log(error)
            })
         axios
            .get('/api/Beca/TipoBeneficio')
            .then((response) => {
               this.cmbCategorias = response.data
            })
            .catch(function (error) {
               console.log(error)
            })
      },
      showDetalles(item) {
         this.carnet = item.carnet
         this.periodosLectivos = []
         this.periodosLectivos.push(item.periodoLectivo)
         this.dialogBecaComp = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      obtenerEstadisticas() {
         axios
            .get(
               'api/Beca/Reportes/Estadistica?periodoLectivo=' +
                  this.selectCicloBecas
            )
            .then((response) => {
               var result = []
               let index = 0
               response.data.forEach((el) => {
                  switch (index) {
                     case 0:
                        result.push(el)
                        break
                     case 1:
                        result.push(el)
                        break
                     case 2:
                        result.push(el)
                        break
                     case 3:
                        result.push(el)
                        break
                     case 4:
                        result[2].pregrado += el.pregrado
                        result[2].postgrado += el.postgrado
                        result[2].doctorado += el.doctorado
                        result[2].cantidad += el.cantidad
                        result[2].aprobadasPorConsejo += el.aprobadasPorConsejo
                        break
                     case 5:
                        result[3].pregrado += el.pregrado
                        result[3].postgrado += el.postgrado
                        result[3].doctorado += el.doctorado
                        result[3].cantidad += el.cantidad
                        result[3].aprobadasPorConsejo += el.aprobadasPorConsejo
                        break
                     default:
                        break
                  }
                  index++
               })

               result[2].categoria = result[2].categoria.replace(
                  ' Administrativas',
                  ''
               )
               result[3].categoria = result[3].categoria.replace(
                  ' Administrativas',
                  ''
               )
               this.estadisticaBeca = result
            })
            .catch((error) => {
               console.log(error)
            })
      },

      reporteBecasAplicadas() {
         this.dialogReporteLoading = true
         var resPago = []
         let heading = []
         let headers = []
         let ruta = ''
         let filename = ''

         let resPagoFiltered = []
         let filtered = []
         switch (this.opt) {
            case 'r-completo':
               ruta =
                  'api/Beca?PeriodoLectivo=' +
                  this.selectCicloBecas +
                  '&OrderByAsc=false'
               filename = 'Becas aplicadas ' + this.selectCicloBecas + '.xlsx'
               heading = [
                  [
                     'Carnet',
                     'Nombre',
                     'PeriodoLectivo',
                     'Categoria',
                     'Descripcion',
                     'Carrera',
                     'MontoABecar',
                     'Benficiario',
                     'Reaplicación'
                  ]
               ]
               headers = [
                  'carnet',
                  'nombreCompleto',
                  'carrera',
                  'periodoLectivo',
                  'tipoBecaDescripcion',
                  'tipoBeneficiario',
                  'categoria',
                  'montoDonado',
                  'reaplicacion'
               ]
               break
            case 'r-facultad':
               ruta =
                  'api/Beca/Reportes/Porfacultad?periodoLectivo=' +
                  this.selectCicloBecas
               filename =
                  'Becas aplicadas ' +
                  this.selectCicloBecas +
                  ' por facultad' +
                  '.xlsx'
               heading = [['Facultad', 'Monto']]
               headers = ['facultad', 'monto']
               break
            case 'r-carrera':
               ruta =
                  'api/Beca/Reportes/PorCarrera?periodoLectivo=' +
                  this.selectCicloBecas
               filename =
                  'Becas aplicadas ' +
                  this.selectCicloBecas +
                  ' por carrera' +
                  '.xlsx'
               heading = [['Carrera', 'Monto']]
               headers = ['carrera', 'monto']
               break
            case 'r-beneficiario':
               ruta =
                  'api/Beca/Reportes/PorBeneficio?periodoLectivo=' +
                  this.selectCicloBecas
               filename =
                  'Becas aplicadas ' +
                  this.selectCicloBecas +
                  ' por beneficiario' +
                  '.xlsx'
               heading = [['Beneficiario', 'Monto']]
               headers = ['tipoBeneficio', 'monto']
               break
            default:
               break
         }

         axios
            .get(ruta)
            .then((response) => {
               resPago = response.data
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               resPago.forEach((beca) => {
                  filtered = []
                  filtered.push(
                     Object.keys(beca)
                        .filter((key) => headers.includes(key))
                        .reduce((obj, key) => {
                           return {
                              ...obj,
                              [key]: beca[key]
                           }
                        }, {})
                  )
                  resPagoFiltered.push(...filtered)
               })
               //today = today.substring(0, 16)
               var wb = XLSX.utils.book_new()

               // Agregando headers y data de arreglo
               var ws = XLSX.utils.aoa_to_sheet(heading)
               XLSX.utils.sheet_add_json(ws, resPagoFiltered, {
                  skipHeader: true,
                  origin: 'A2'
               })

               // Asignando ancho de columnas segun contenido
               this.autofitColumns(resPagoFiltered, ws)

               // Agregando hoja y exportando archivo
               XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
               XLSX.writeFile(wb, filename)
               this.dialogReporteLoading = false
            })
      }
   }
}
</script>
